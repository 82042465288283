import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { RutaService } from 'src/app/services/operaciones/ruta.service';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-cancelacion-ruta',
  templateUrl: './cancelacion-ruta.component.html',
  styleUrls: ['./cancelacion-ruta.component.scss', '../estilosModales.scss']
})
export class CancelacionRutaComponent implements OnInit {

  rutas = [];
  canceladas = [];

  settings: Settings;

  constructor(public dialogRef: MatDialogRef<CancelacionRutaComponent>, private _rs: RutaService,
    public appSettings:AppSettings) {
      this.settings = this.appSettings.settings;
    }

  ngOnInit() {
    this.consultarRutas();
  }

  cerrarModal(tipo) {
    this.dialogRef.close(tipo);
  }

  consultarRutas() {
    this._rs.consultarRutasCancelacion(1).subscribe(
      result => {
        this.rutas = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  cancelarRutas() {
    this.settings.loadingSpinner = true;
    let rutasCanceladas = []
    for(let c of this.canceladas){
      let m = {
        idRuta: c
      }
      rutasCanceladas.push(m);
    }
    this._rs.cancelarRutas(rutasCanceladas).subscribe(
      result => {
        this.settings.loadingSpinner = false;
        this.cerrarModal(true);
      },
      error => {
        this.settings.loadingSpinner = false;
        console.log(error);
      }
    );
  }

}
