import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-imagen',
  templateUrl: './imagen.component.html',
  styleUrls: ['./imagen.component.scss', '../../estilosModales.scss']
})
export class ImagenComponent implements OnInit {

  sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl('C:/Users/jesus/Pictures/ciri.jpg');

  constructor(
    public dialogRef: MatDialogRef<ImagenComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit() {
    console.log(this.data);
  }


cerrarModal() {
    this.dialogRef.close();
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ia], {type:mimeString});

    FileSaver.saveAs(blob, this.data.nombreFoto);
}

}
