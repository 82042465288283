export class Sitio{
	constructor(
		public idCedis:number,
		public nombre:string,
		
		public longitud:string,
		public latitud:string,
		
		public codigoCedis: string,
		public idMunicipio: number,
		
		public ciudad: string,
	    public tiempoServicio: number,




    public nombreNegocio:string, 
    public rfc:string,
    public telefono:string,
    public email:string,
    public calle:string,
    public numero:string,
    public numeroExterior:string,
    public colonia:string,
    public entreCalles:string,
    public contacto2:string,
    public contacto1:string,
    public pais:string,
    public municipio:string,
    public estado:string,
    public idPais:number,
    public tipo:number,
    public cliente:number,

    public idRazonSocial:number,
    public realizoBaja:number,
    public realizoAlta:number,
    public realizoCambio:number,
    public estatus:string

	){}
}