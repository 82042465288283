import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { RutaService } from 'src/app/services/operaciones/ruta.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagenComponent } from '../imagen/imagen.component';
import { ExcelService } from 'src/app/services/excel.service';
import { utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-evidencias',
  templateUrl: './evidencias.component.html',
  styleUrls: ['./evidencias.component.scss', '../../estilosModales.scss']
})
export class EvidenciasComponent implements OnInit {
  public displayedColumns = ['numero', 'segmento', 'pedido', 'fecha','hora', 'descripcion', 'ubicacion', 'fotografia'];
  dataSource: Array<any> = [];
  evidencias: Array<any> = [];
  usuarioSession: any;
  isZAM: any;
  isZAMVal: boolean=false;
  idViajeZAM: any[];
  public _utils:utils;

  constructor(
    public dialogRef: MatDialogRef<EvidenciasComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rs: RutaService,
    public sanitizer: DomSanitizer,
    private excelService: ExcelService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this._utils = new utils();
    this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
    this.isZAM = this.usuarioSession.activoZAM;
    if(this.isZAM != 0){
      this.isZAMVal = true
    }else{
      this.isZAMVal = false
    }
    this.consultarEvidencias();
    this.consultarRutaViajeZAM();
  }

  consultarEvidencias() {
    this._rs.consultarRutaEvidencias(this.data.idRuta).subscribe(
      result => {
        this.evidencias = result;
        this.dataSource = this.evidencias;
        console.log(this.evidencias);
      },
      error => {
        console.log(error);
      }
    );
  }

  consultarRutaViajeZAM() {
    this._rs.consultarRutaViajeZAM(this.data.idRuta).subscribe(
      result => {
        this.idViajeZAM = result[0].idRutaExterna;
      },
      error => {
        console.log(error);
      }
    );
  }

  exportar():void {
    if(this.evidencias.length > 0){
      for(var x=0; x<this.evidencias.length; x++){
        delete this.evidencias[x].b64;
        delete this.evidencias[x].extensionFoto;
        delete this.evidencias[x].id;
        delete this.evidencias[x].idRazonSocial;
        delete this.evidencias[x].nombreFoto;
        delete this.evidencias[x].pathFoto;
        delete this.evidencias[x].tipo;
      }
      this.excelService.exportAsExcelFile(this.evidencias, 'evidencias');
    }else{
      //console.log("No hay evidencias para mostrar.")
      this._utils.toast("No hay evidencias para exportar!",this.snackBar);
    }
  }

  cerrarModal() {
    this.dialogRef.close();
  }

  modalImagen(e: any) {
    const diagRef = this.dialog.open(ImagenComponent, {
      disableClose: false,
      width: '75%',
      height: '90%',
      data: e
    });
  }

}
