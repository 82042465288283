import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../utils/data.service';
import { Observable } from 'rxjs/Observable';
import { modalComponent } from '../../../utils/modal/modal.component';
import { ImportadorService } from '../../../services/importador.service';
import { MatDialog,MatSnackBar } from '@angular/material';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material';

import * as $ from 'jquery';

import { utils } from '../../../utils/utils';



@Component({
  selector: 'app-operador-add-edit',
  templateUrl: './importador.component.html',
  styleUrls: ['../estilosModales.scss']
})
export class ImportadorComponent implements OnInit {
 public _utilsOp : utils;
  public file: File;
  public tipo: any;
  public usuarioSession : any;
  public idRazonSocial;
  isZAM: any;
  isZAMVal: boolean=false;
  constructor(
             
               public dialog: MatDialogRef<ImportadorComponent>,
          
              @Inject(MAT_DIALOG_DATA) public data: any,public snackBar: MatSnackBar,public dialogOpe: MatDialog,private importadorService: ImportadorService,public dataService: DataService) {
                
                this._utilsOp = new utils();
               }

  ngOnInit() {
    this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
    this.idRazonSocial = this.usuarioSession.idRazonSocial;
    this.isZAM = this.usuarioSession.activoZAM;
    if(this.isZAM != 0){
      this.isZAMVal = true
    }else{
      this.isZAMVal = false
    }
     console.log(this.tipo);
  }


  cerrarModal() {
    this.dataService.dialogRef.close();
  }

  handleFileInput(file: FileList,position : number) {
   
    if (file.item.length === 0) {
      return; // maybe needs more checking
   }
   this.file = file.item(0);
}

guardarArchivos(){
 
  if(this.file != null){
  switch(this.tipo) { 
   case 1: { 
      //statements; 
      this.guardarArchivoOperador();
      break; 
   } 
   case 2: { 
      //statements; 
      this.guardarArchivoVehiculo();
            break; 
   } 
    case 3: { 
      //statements; 
      this.guardarArchivoCliente();
            break; 
   } 
    case 4: { 
      //statements; 
      this.guardarArchivoProductos();
            break; 
   } 
   case 5: { 
    //statements; 
    this.guardarArchivoSolicitudes();
          break; 
 } 
   default: { 
      //statements; 
      break; 
   } 
} 
}else{
  this._utilsOp.toast("Favor de seleccionar un archivo para importar",this.snackBar);
  }
}


guardarArchivoOperador(){
this.importadorService.cargarOperadores(this.file,this.idRazonSocial).subscribe(
      (data) => {
       if(data == "OK"){
          this.dataService.dialogRef.close(1);
       }else{
         this._utilsOp.toast(data,this.snackBar);
       }
       },
      (error) =>  {
        console.log(error);
      }
    );

}

guardarArchivoSolicitudes(){
  this.importadorService.cargarSolicitudes(this.file,this.idRazonSocial).subscribe(
        (data) => {
         if(data == "OK"){
            this.dataService.dialogRef.close(1);
         }else{
           this._utilsOp.toast(data,this.snackBar);
         }
         },
        (error) =>  {
          console.log(error);
        }
      );
  
  }

guardarArchivoVehiculo(){
this.importadorService.cargarVehiculos(this.file,this.idRazonSocial).subscribe(
      (data) => {
       if(data == "OK"){
          this.dataService.dialogRef.close(1);
       }else{
         this._utilsOp.toast(data,this.snackBar);
       }
       },
      (error) =>  {
        console.log(error);
      }
    );

}

guardarArchivoCliente(){
this.importadorService.cargarClientes(this.file,this.idRazonSocial).subscribe(
      (data) => {
       if(data == "OK"){
          this.dataService.dialogRef.close(1);
       }else{
         this._utilsOp.toast(data,this.snackBar);
       }
       },
      (error) =>  {
        console.log(error);
      }
    );

}

guardarArchivoProductos(){
this.importadorService.cargarProductos(this.file,this.idRazonSocial).subscribe(
      (data) => {
       if(data == "OK"){
          this.dataService.dialogRef.close(1);
       }else{
         this._utilsOp.toast(data,this.snackBar);
       }
       },
      (error) =>  {
        console.log(error);
      }
    );

}

  getArchivo() {
    
  switch(this.tipo) { 
   case 1: { 
      //statements; 
      this.getArchivoOperador();
      break; 
   } 
   case 2: { 
      this.getArchivoVehiculo();
      //statements; 
      break; 
   } 
    case 3: { 
      this.getArchivoCliente();
      //statements; 
      break; 
   } 
    case 4: { 
      this.getArchivoProductos();
      //statements; 
      break; 
   } 
   case 5: { 
    this.getArchivoSolicitudes();
    //statements; 
    break; 
 } 
   default: { 
      //statements; 
      break; 
   } 
} 
  
}


getArchivoOperador(){
  this.importadorService.getArchivoOperadores().subscribe(
      (data) => {
       this.downLoadFile(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","Operadores");
       },
      (error) =>  {
        console.log(error);
      }
    );
}

getArchivoVehiculo(){
  this.importadorService.getArchivoVehiculos().subscribe(
      (data) => {
       this.downLoadFile(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","Vehiculos");
       },
      (error) =>  {
        console.log(error);
      }
    );
}

getArchivoSolicitudes(){
  this.importadorService.getArchivoSolicitudes().subscribe(
      (data) => {
       this.downLoadFile(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","Solicitudes");
       },
      (error) =>  {
        console.log(error);
      }
    );
}

getArchivoCliente(){
  this.importadorService.getArchivoClientes().subscribe(
      (data) => {
       this.downLoadFile(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","Clientes");
       },
      (error) =>  {
        console.log(error);
      }
    );
}

getArchivoProductos(){
  this.importadorService.getArchivoProductos().subscribe(
      (data) => {
       this.downLoadFile(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","Productos");
       },
      (error) =>  {
        console.log(error);
      }
    );
}

 downLoadFile(data: any, type: string, name: string) {
        var blob = new Blob([data._body],{ type: type });

       // var url = window.URL.createObjectURL(blob);
          const url = window.URL.createObjectURL(blob);
    const urlDescarga = document.createElement('a');
    urlDescarga.href = url;
    urlDescarga.download = name;
    document.body.appendChild(urlDescarga);
    urlDescarga.click();
    document.body.removeChild(urlDescarga);
    window.URL.revokeObjectURL(url);
        var pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert( 'Please disable your Pop-up blocker and try again.');
        }
    }



 

}