import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Http } from '@angular/http';


@Injectable({
  providedIn: 'root'
})
export class AccesoPantallaGuard implements CanActivate {

   dataUsuario: any;
   accesos: any;
   permisoPantalla: boolean = false;
  	constructor(private _router: Router, private _http: Http){ }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
        this.dataUsuario = JSON.parse(sessionStorage.getItem("usuario"));
        this.accesos = JSON.parse(sessionStorage.getItem("accesos"));

        if(this.dataUsuario === null || this.dataUsuario === undefined)
        {
            this._router.navigate(['/login']);
            return false;
        }
        else{
          this.permisoPantalla = false;
          for(var i = 0; i < this.accesos.length; i++){
            if(this.accesos[i].codigo == route.data.codigo){
                this.permisoPantalla = true;
            }
          }
          if(this.permisoPantalla){
            return true;
          }else {
            this._router.navigate(['/pages']);
            return false;
          }
        }
    }
  
}
