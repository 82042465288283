import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Http } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class CerrarSesionGuard implements CanActivate {

	constructor(private _router:Router, private _http:Http){ }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|boolean {
        sessionStorage.removeItem("usuario");
    	return true;
    }
  
}
