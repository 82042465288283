import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { AccesoService } from 'src/app/services/acceso.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService, AccesoService ]
})
export class SidenavComponent implements OnInit {
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation:true
  };
  public menuItems:Array<any>;
  public menuAcceso:Array<any> = [];
  public settings: Settings;
  public base64: any;
  public usuario: any;
  public accesos: any = [];
  
  constructor(public appSettings:AppSettings, public menuService:MenuService, private _as: AccesoService){
      this.settings = this.appSettings.settings; 
  }


  ngOnInit() {
    this.base64 = true;
    this.usuario = JSON.parse(sessionStorage.getItem("usuario"));
    if(this.usuario.b64 == ""){
      this.base64 = false
    }
    this.menuItems = this.menuService.getVerticalMenuItems();

    if( JSON.parse(sessionStorage.getItem("accesos")))
      this.accesos =  JSON.parse(sessionStorage.getItem("accesos"));
    
    for(var i = 0; i < this.menuItems.length; i++){
      for(var j = 0; j < this.accesos.length; j++){
        if(this.menuItems[i].codigo == this.accesos[j].codigo){
          this.menuAcceso.push(this.menuItems[i])
        }
      }
    }
  }

  ngDoCheck(){
    if(this.settings.fixedSidenav){
      if(this.psConfig.wheelPropagation == true){
        this.psConfig.wheelPropagation = false;
      }      
    }
    else{
      if(this.psConfig.wheelPropagation == false){
        this.psConfig.wheelPropagation = true;
      }  
    }
  }

  public closeSubMenus(){
    let menu = document.getElementById("vertical-menu");
    if(menu){
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if(child){
          if(child.children[0].classList.contains('expanded')){
              child.children[0].classList.remove('expanded');
              child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

}
