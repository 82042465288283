import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { RutaService } from 'src/app/services/operaciones/ruta.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-paradas-visitadas',
  templateUrl: './paradasVisitadas.component.html',
  styleUrls: [ './paradasVisitadas.component.scss','../../estilosModales.scss']
})
export class ParadasVisitadasComponent implements OnInit {
  public displayedColumns = ['folio', 'cliente', 'sitio','operador', 'vehiculo', 'pedido', 'fecha', 'hora','fechaEntrega', 'horaEntrega','Indicador'];
  dataSource: Array<any> = [];
  evidencias: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<ParadasVisitadasComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rs: RutaService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.consultarParadasvisitadas();
  }

  consultarParadasvisitadas() {
    this._rs.consultarParadasVisitadas(this.data.idRuta).subscribe(
      result => {
        
        this.dataSource = result;
         for(let obj of this.dataSource) {
           if(obj.horas < 0){
             obj.color = "rojo"
           }else{
             obj.color = "verde"
           }

           if(obj.minutos < 10){
             obj.total = +obj.horas + ':0' + +obj.minutos
           }else{
              obj.total = +obj.horas + ':' + +obj.minutos
           }
         }
        console.log(this.evidencias);
      },
      error => {
        console.log(error);
      }
    );
  }

  exportarPDF(){
    this._rs.exportarPDF(this.dataSource).subscribe(
   result => {
        this.downLoadFile(result);
      },
      error => {
        console.log(error);
      }
      );
  }

  cerrarModal() {
    this.dialogRef.close();
  }
  
  downLoadFile(data:any){
       const linkSource = 'data:application/pdf;base64,' + data
        const downloadLink = document.createElement("a");
        const fileName = "Informacion de la Parada.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


 /* downLoadFile(data: any, type: string, name: string) {
        var blob = new Blob([data._body],{ type: type });

       // var url = window.URL.createObjectURL(blob);
          const url = window.URL.createObjectURL(blob);
    const urlDescarga = document.createElement('a');
    urlDescarga.href = url;
    urlDescarga.download = name;
    document.body.appendChild(urlDescarga);
    urlDescarga.click();
    document.body.removeChild(urlDescarga);
    window.URL.revokeObjectURL(url);
        var pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert( 'Please disable your Pop-up blocker and try again.');
        }
    }*/

    
  

}
