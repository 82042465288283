import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject'; 

@Injectable()
export class DataService {
	id: number;
	tipo:string;
	dialogRef:any;
	dialogModal:any;
	guardado:boolean;
	modal:string;
	mensaje:string;
	acceso:any;
	mensajeAlert:string;
	folioPrograma: number;
	fechaPrograma: Date;
	pedidos: any[];
}