export class TransportistaModelo{
	constructor(
        public idTransportista:number, 
        public nombre:string,
        public contacto1:string,
        public contacto2:string,
        public correo1:string,
        public correo2:string,
        public telefono: string,
        public idRazonSocial:number,
        public realizoAlta:number,
        public rfc : string,
      
	){}
}


