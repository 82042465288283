import { Component, OnInit } from '@angular/core';
import { DataService } from '../../utils/data.service';

@Component({
  selector: 'my-modal',
  styles: ['.mat-button.mat-success,  .mat-stroked-button.mat-success { color: #155724;  }'],
  templateUrl: './modal.component.html'
})

export class modalComponent implements OnInit {

  public confirmModal:boolean;
  public mensaje:string;

  constructor(public dataService: DataService) { 
    this.dataService.guardado=false;
    this.mensaje = "";
    this.confirmModal = false;
  }
  
  ngOnInit() {  
    if(this.dataService.modal == "confirm"){
      this.confirmModal = true;
    }
    this.mensaje = this.dataService.mensaje;
  }

  aceptar(){
    if(this.confirmModal){
      this.dataService.guardado=true;
      this.cerrarModal();
    }
    else{
      this.dataService.dialogModal.close();
    }
  }

  cerrarModal(){
    this.dataService.dialogModal.close();
  }

}