import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { RutaService } from 'src/app/services/operaciones/ruta.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagenComponent } from '../imagen/imagen.component';
import { ExcelService } from 'src/app/services/excel.service';
import { utils } from 'src/app/utils/utils';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {
  public displayedColumns = ['numero','viaje', 'fecha','hora', 'descripcion', 'ubicacion', 'fotografia'];
  dataSource: Array<any> = [];
  eventos: Array<any> = [];
  exportEventos: Array<any> = [];
  usuarioSession: any;
  isZAM: any;
  isZAMVal: boolean=false;
  idViajeZAM: any;
  public _utils:utils;
  dateExcel:number;
  pipe = new DatePipe('en-US');
  link :string ='https://www.google.com/maps/search/?api=1&query=';
  finlink:string = '';
  constructor(
    public dialogRef: MatDialogRef<EventosComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rs: RutaService,
    public sanitizer: DomSanitizer,
    private excelService: ExcelService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.dateExcel = new Date().getTime() ;
    this._utils = new utils();
    this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
    this.isZAM = this.usuarioSession.activoZAM;
    if(this.isZAM != 0){
      this.isZAMVal = true
    }else{
      this.isZAMVal = false
    }
    this.consultarEventos();
    this.consultarRutaViajeZAM();
  }

  consultarEventos() {
    this._rs.consultarRutaEventos(this.data.idRuta).subscribe(
      result => {
        this.eventos = result;
        this.dataSource = this.eventos;
      },
      error => {
        console.log(error);
      }
    );
  }
  consultarRutaViajeZAM() {
    this._rs.consultarRutaViajeZAM(this.data.idRuta).subscribe(
      result => {
        this.idViajeZAM = result[0].idRutaExterna;
      },
      error => {
        console.log(error);
      }
    );
  }

  exportar():void {
    if(this.eventos.length > 0){
      //Recorrer y quitar la propiedad pathFot (no incluir eso al excel).
      console.log(this.eventos)
      for(var x=0; x<this.eventos.length; x++){
        delete this.eventos[x].pathFoto;
        delete this.eventos[x].nombreFoto;
        delete this.eventos[x].extensionFoto;
        //delete this.eventos[x].idEventoTipo;
        delete this.eventos[x].idRazonSocial;
        delete this.eventos[x].idRuta;
        delete this.eventos[x].idRutaEvento;
        delete this.eventos[x].b64;

        this.exportEventos.push({'#':this.eventos[x].numeroEvento,'Viaje':this.eventos[x].idViaje,'Fecha':this.pipe.transform(this.eventos[x].fecha,'dd-MM-yyyy'),'Hora':this.pipe.transform(this.eventos[x].fecha,'HH:mm'),'Descripción':this.eventos[x].descripcionEvento,'Ubicación': 'https://www.google.com/maps/search/?api=1&query='+this.eventos[x].latitud+','+this.eventos[x].longitud,'Fotografia':''});

      }
      console.log(this.exportEventos);
      this.excelService.exportAsExcelFile(this.exportEventos, 'evidencias');
    }else{
      //console.log("No hay evidencias para mostrar.")
      this._utils.toast("No hay eventos para exportar!",this.snackBar);
    }
  }

  cerrarModal() {
    this.dialogRef.close();
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  modalImagen(e: any) {
    const diagRef = this.dialog.open(ImagenComponent, {
      disableClose: false,
      width: '75%',
      height: '90%',
      data: e
    });
  }

}
