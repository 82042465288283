import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutocomplete]'
})
export class AutocompleteDirective {

  constructor(private el: ElementRef) { 
    el.nativeElement.autocomplete = "off";
  }

  @HostListener('keydown') onKeyDown() {
    this.el.nativeElement.autocomplete = "on";
  }

}
