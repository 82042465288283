import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatAutocompleteModule,
         MatButtonModule,
         MatButtonToggleModule,
         MatCardModule,
         MatCheckboxModule,
         MatChipsModule,
         MatDatepickerModule,
         MatDialogModule,
         MatExpansionModule,
         MatGridListModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatMenuModule,
         MatNativeDateModule,
         MatPaginatorModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatRadioModule,
         MatRippleModule,
         MatSelectModule,
         MatSidenavModule,
         MatSliderModule,
         MatSlideToggleModule,
         MatSnackBarModule,
         MatSortModule,
         MatTableModule,
         MatTabsModule,
         MatToolbarModule,
         MatTooltipModule,
         MatStepperModule } from '@angular/material';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { EventosComponent } from '../pages/views/monitor/eventos/eventos.component';
import { SubirArchivoRoutechComponent } from '../pages/views/operaciones/subir-archivo-routech/subir-archivo-routech.component';
import { EvidenciasComponent } from '../pages/views/monitor/evidencias/evidencias.component';
import { ProductoComponent} from '../pages/views/programas/producto/producto.component'
import { ImagenComponent } from '../pages/views/monitor/imagen/imagen.component';
import { ImportadorComponent } from '../pages/views/importador/importador.component'
import { SitiosAddComponent } from '../pages/views/programas/sitio/sitiosAdd.component'
import { ParadasVisitadasComponent} from '../pages/views/monitor/paradasVisitadas/paradasVisitadas.component'
import { GastoComponent } from 'src/app/pages/views/programas/gasto/gasto.component'
import { CancelacionRutaComponent } from '../pages/views/cancelacion-ruta/cancelacion-ruta.component';
import { AutocompleteDirective } from './directives/autocomplete.directive';
import { MatTableExporterModule } from 'mat-table-exporter';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    NgSelectModule,
    MatTableExporterModule
  ],
  exports: [
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    ContentHeaderComponent,
    BreadcrumbComponent,
    EventosComponent,
    SubirArchivoRoutechComponent,
    EvidenciasComponent,
    ImagenComponent,
    ImportadorComponent,
    ParadasVisitadasComponent,
    SitiosAddComponent,
    GastoComponent,
    CancelacionRutaComponent,
    AutocompleteDirective,
    ProductoComponent,
    MatTableExporterModule
  ],
  declarations: [
    ContentHeaderComponent,
    BreadcrumbComponent,
    EventosComponent,
    SubirArchivoRoutechComponent,
    EvidenciasComponent,
    ImagenComponent,
    ImportadorComponent,
    ParadasVisitadasComponent,
    SitiosAddComponent,
    GastoComponent,
    CancelacionRutaComponent,
    AutocompleteDirective,
    ProductoComponent
  ]
  
})
export class SharedModule { }
