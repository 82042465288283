import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {
   // Doughnut
   private options: any = {
    legend: { position: 'right' },
    title: {
      display: true,
      text: 'Ocupación Flota',
      fontColor: 'black',  // chart title color (can be hexadecimal too)
    },
   
  

  }

  private donutColors=[
    {
      backgroundColor: [
        'rgba(153, 51, 51, .9)',
        'rgba(25, 25, 127, .9)',
        'rgba(10, 96,10, .9)',
        'rgba(227, 248, 67, .9)',
        'rgba(170, 171, 164, .9)'
    ]
    }
  ];
   public doughnutChartLabels: Label[] = ['Programas del Dia', 'En curso con Alerta', 'En curso a tiempo','Con retraso','Creados','Unidades Disponibles'];
   public doughnutChartData: number[] = [
    94, 40, 27,27,27,16
  ];
   public doughnutChartType: ChartType = 'doughnut';

   public doughnutChartLabels2: Label[] = ['En curso', 'Entregados', 'Por entregar','Cancelados','Entregas Parciales'];
   public doughnutChartData2: number[] = [
    67, 40, 20,7,2
  ];
   public doughnutChartType2: ChartType = 'doughnut';
  constructor() { 
    
  }

  ngOnInit() {
  }

}
