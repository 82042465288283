import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import {DashboardComponent } from './pages/dashboard/dashboard.component'
import { CerrarSesionGuard } from './_guards/cerrar-sesion.guard';
import { AccesoPantallaGuard } from './_guards/acceso-pantalla.guard';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'pages', 
        component: PagesComponent, children: [
            { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'Dashboard' } },
            { path: 'users', loadChildren: './pages/users/users.module#UsersModule', data: { breadcrumb: 'Users' } },
            { path: 'dynamic-menu', loadChildren: './pages/dynamic-menu/dynamic-menu.module#DynamicMenuModule', data: { breadcrumb: 'Dynamic Menu' }  },         
            { path: 'ui', loadChildren: './pages/ui/ui.module#UiModule', data: { breadcrumb: 'UI' } },
            { path: 'mailbox', loadChildren: './pages/mailbox/mailbox.module#MailboxModule', data: { breadcrumb: 'Mailbox' } },
            { path: 'chat', loadChildren: './pages/chat/chat.module#ChatModule', data: { breadcrumb: 'Chat' } },
            { path: 'form-controls', loadChildren: './pages/form-controls/form-controls.module#FormControlsModule', data: { breadcrumb: 'Form Controls' } },
            { path: 'tables', loadChildren: './pages/tables/tables.module#TablesModule', data: { breadcrumb: 'Tables' } },
            { path: 'schedule', loadChildren: './pages/schedule/schedule.module#ScheduleModule', data: { breadcrumb: 'Schedule' } },
            { path: 'maps', loadChildren: './pages/maps/maps.module#MapsModule', data: { breadcrumb: 'Maps' } },
            { path: 'charts', loadChildren: './pages/charts/charts.module#ChartsModule', data: { breadcrumb: 'Charts' } },
            { path: 'drag-drop', loadChildren: './pages/drag-drop/drag-drop.module#DragDropModule', data: { breadcrumb: 'Drag & Drop' } },
            { path: 'icons', loadChildren: './pages/icons/icons.module#IconsModule', data: { breadcrumb: 'Material Icons' } },
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
            { path: 'search/:name', component: SearchComponent, data: { breadcrumb: 'Search' } },
            //{ path: 'importador', loadChildren: './pages/importador/importador.module#ImportadorModule', data: { breadcrumb: 'Importador' }, canActivate: [AccesoPantallaGuard] },
            { path: 'pais', loadChildren: './pages/views/configuracion/catalogos/generales/pais/pais.module#PaisModule', data: { breadcrumb: 'Pais' }, canActivate: [AccesoPantallaGuard] },
            { path: 'configuracion', loadChildren: './pages/views/configuracion/configuracion.module#configuracionModule', data: { breadcrumb: 'Configuración', codigo: 'CONFIG' }, canActivate: [AccesoPantallaGuard] },
            //{ path: 'programas', loadChildren: './pages/views/programas/programas/programas.module#ProgramasModule', data: {breadcrumb: 'Programas', codigo: 'PROG'}, canActivate: [AccesoPantallaGuard]},
            { path: 'rutas', loadChildren: './pages/views/operacion/rutas/rutas/rutas.module#RutasModule', data: {breadcrumb: 'Rutas', codigo: 'PROG'}, canActivate: [AccesoPantallaGuard]},
            { path: 'operaciones', loadChildren: './pages/views/operaciones/operaciones.module#OperacionesModule', data: {breadcrumb: 'Operaciones', codigo: 'OPE'}, canActivate: [AccesoPantallaGuard]},
            { path: 'monitor', loadChildren: './pages/views/monitor/monitor.module#MonitorModule', data: {breadcrumb: 'Monitor', codigo: 'MON'}, canActivate: [AccesoPantallaGuard]},
            { path: 'ocupacion-flota', loadChildren: './pages/views/ocupacion-flota/ocupacion-flota.module#OcupacionFlotaModule', data: {breadcrumb: 'Ocupación Flota', codigo: 'OCUFLO'}, canActivate: [AccesoPantallaGuard]},
            { path: 'bolsa-transportista', loadChildren: './pages/views/bolsaTransportista/bolsaTransportista.module#BolsaTransportistaModule', data: {breadcrumb: 'Bolsa Transportista', codigo: 'BOTRAN'}, canActivate: [AccesoPantallaGuard]},
            { path: 'confirmar-documentos', loadChildren: './pages/views/confirmar-documentos/confirmar-documentos.module#ConfirmarDocumentosModule', data: {breadcrumb: 'Confirmar Documentos', codigo: 'CONDOC'}, canActivate: [AccesoPantallaGuard]},
            { path: 'convenios', loadChildren: './pages/views/convenio/convenio.module#ConvenioModule', data: {breadcrumb: 'Convenio', codigo: 'OCUFLO'}, canActivate: [AccesoPantallaGuard]},
            { path: 'configuracion-app', loadChildren: './pages/views/configuracion/configuracion-app/configuracion-app.module#ConfiguracionAppModule', data: {breadcrumb: 'Configuracion App', codigo: 'OCUFLO'}, canActivate: [AccesoPantallaGuard]},
            { path: 'embarques', loadChildren: './pages/views/embarques/embarques.module#EmbarquesModule', data: {breadcrumb: 'Embarques', codigo: 'EMBA'}, canActivate: [AccesoPantallaGuard]},
            { path: 'programa-embarques', loadChildren: './pages/views/programa-embarques/programa-embarques.module#ProgramaEmbarquesModule', data: {breadcrumb: 'Programa Embarques', codigo: 'PROEMBA'}},
            { path: 'ubicacion-contenedores', loadChildren: './pages/views/ubicacion-contenedores/ubicacion-contenedores.module#UbicacionContenedoresModule', data: {breadcrumb: 'Ubicacion Contenedores', codigo: 'UBCONT'},canActivate: [AccesoPantallaGuard]},
            { path: 'controlador-contenedores', loadChildren: './pages/views/controlador-contenedores/controlador-contenedores.module#ControladorContenedoresModule', data: {breadcrumb: 'Controlador Contenedores', codigo: 'CTRLCONT'},canActivate: [AccesoPantallaGuard]},
            { path: 'disponibilidad-contenedores', loadChildren: './pages/views/disponibilidad-contenedores/disponibilidad-contenedores.module#DisponibilidadContenedoresModule', data: {breadcrumb: 'Disponibilidad Contenedores', codigo: 'DISPCONT'},canActivate: [AccesoPantallaGuard]},
            // { path: 'disponibilidad-unidades', loadChildren: './pages/views/disponibilidad-unidades/disponibilidad-unidades.module#DisponibilidadUnidadesModule', data: {breadcrumb: 'Disponibilidad Unidades', codigo: 'OCUFLO'}},
            // { path: 'ubicacion-unidades', loadChildren: './pages/views/ubicacion-unidades/ubicacion-unidades.module#UbicacionUnidadesModule', data: {breadcrumb: 'Ubicacion Unidades', codigo: 'OCUFLO'}},
            { path: 'pedidos', loadChildren: './pages/views/pedidos/pedidos.module#PedidosModule', data: {breadcrumb: 'Pedidos', codigo: 'PED'}},
            { path: 'programa-solicitudes', loadChildren: './pages/views/programa-solicitudes/programa-solicitudes.module#ProgramaSolicitudesModule', data: {breadcrumb: 'Programa Solicitudes', codigo: 'OCUFLO'}},
        ]
    },

    { path: 'ruta-transportista', loadChildren: './pages/views/ruta-transportista/ruta-transportista.module#RutaTransportistaModule' },
    { path: 'landing', loadChildren: './pages/landing/landing.module#LandingModule' },
    { path: 'login', loadChildren: './pages/login/login.module#LoginModule', canActivate: [CerrarSesionGuard] },
    { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }

];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
   preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   // useHash: true
});