import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../../utils/data.service';
import { TransportistaService } from '../../../../services/configuracion/catalogos/operaciones/transportista/transportista.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-vehiculo-tipo-transportista',
  templateUrl: './vehiculoTipoTransportista.component.html',
  styleUrls: ['../../estilosModales.scss'],
})
export class VehiculoTipoTransportista implements OnInit {
  
  
 public usuarioSession: any;
  public idRazonSocial: any;
  public descripcion: any;
  tipoAgregar: FormGroup;
  titulo = 'Agregar';
  public idTipoProducto: number = null;
  public chckActivado = false;

  constructor(private formBuilder: FormBuilder, private transportistaService: TransportistaService, public dialogRef: MatDialogRef<VehiculoTipoTransportista>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
     this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario"));
       
    this.idRazonSocial = this.usuarioSession.idRazonSocial;
      this.buildForm();  
  }

  cerrarModal() {
    this.dialogRef.close();
  }

  buildForm() {
    this.tipoAgregar = this.formBuilder.group({
      descripcion: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)])
    });
  }

  agregar() {
    const tipo = 'agregar';
  
   let params={
     nombre : this.descripcion,
     idRazonSocial : this.idRazonSocial
   }
    
    this.transportistaService.agregarVehiculoTipoTransportista(params).subscribe(
        (result) => {
          if (result !== 'null') {
            this.cerrarModal();
          }
        },
        (error) => {
          console.log(error);
        }
    );
  }

  onSubmit() {
    this.agregar();
  }

}