import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { utils } from '../utils/utils';
import * as $ from 'jquery';
import { Sitio } from '../models/cedis';

@Injectable()
export class CedisService {
    // public usuario:Array<any>;
   public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});
    //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});
   

    constructor(private _http: Http,  private _utils: utils) {
        this._utils = new utils();
    }
   
   
  consultarSitios(idRazonSocial): Observable<Sitio[]>{
 let param = {
    idRazonSocial: idRazonSocial
  }
  const json = JSON.stringify(param);
   
 
      return this._http
        .post(`${this._utils.url}Cedis/consultarSitios/`,json, {headers: this.headers} )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }

 consultarSitiosGrid(idRazonSocial): Observable<any[]>{
   
    let param = {
    idRazonSocial: idRazonSocial
  }
  const json = JSON.stringify(param);
   
  
      return this._http
        .post(`${this._utils.url}Cedis/consultarSitiosCliente/`,json, {headers: this.headers}  )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }



   consultarClientes(idRazonSocial): Observable<any[]>{
   
    let param = {
    idRazonSocial: idRazonSocial
  }
  const json = JSON.stringify(param);
   
  
      return this._http
        .post(`${this._utils.url}Cedis/consultarClientes/`,json, {headers: this.headers}  )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }

   consultarCedisSinClientes(idRazonSocial): Observable<any[]>{
   
    let param = {
    idRazonSocial: idRazonSocial
  }
  const json = JSON.stringify(param);
   
  
      return this._http
        .post(`${this._utils.url}Cedis/consultarCedisSinClientes/`,json, {headers: this.headers}  )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }


    consultarSitiosFull(idRazonSocial): Observable<Sitio[]>{
   
    let param = {
    idRazonSocial: idRazonSocial
  }
  const json = JSON.stringify(param);
   
      return this._http
        .post(`${this._utils.url}Cedis/consultarSitiosFull/` ,json,{headers:this.headers})
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }

      consultarSitiosFiltros(params) : Observable<any[]> {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        const json = JSON.stringify(params);
       ;
        return this._http
          .post(`${this._utils.url}Cedis/consultarSitiosFiltros/`, json, {headers: this.headers} )
          .map((response) => {
             try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
          });
    }

    consultarSitio(idSitio){
       let param = {
    idSitio: idSitio
  }
  const json = JSON.stringify(param);
   
  
      return this._http
        .post(`${this._utils.url}Cedis/consultarSitio/`,json, {headers: this.headers}  )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
    }

      agregarSitio(Sitio: Sitio) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        const json = JSON.stringify(Sitio);
        const params = json;
        return this._http
          .post(`${this._utils.url}Cedis/agregarSitios/`, params, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return response.statusText;
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
    }

      editarSitio(Sitio: Sitio) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        const json = JSON.stringify(Sitio);
        const params = json;
        return this._http
          .post(`${this._utils.url}Cedis/editarSitio/`, params, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return response.statusText;
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
    }

    //Consultar cedis para la pantalla Pedidos
    consultarPedidosCedis(idRazonSocial, tipo) {
      const json = JSON.stringify({"idRazonSocial": idRazonSocial, "tipo": tipo});
      const params = json;
      return this._http
      .post(`${this._utils.url}Cedis/consultarPedidosCedis/`, params, {headers: this.headers} )
      .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (error) {
            console.log(error);
            return null; 
          } 
      });
    }

    consultarCedisTipoCedis(idCedisTipo, idRazonSocial): Observable<any[]>{
        return this._http
          .post(`${this._utils.url}Cedis/consultarCedisTipoCedis?idCedisTipo=`+idCedisTipo+`&idRazonSocial=`+idRazonSocial, {headers: this.headers}  )
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
    }
}