import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../../utils/data.service';
import { Observable } from 'rxjs/Observable';
import { modalComponent } from '../../../../utils/modal/modal.component';
import { MatDialog } from '@angular/material';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material';
import { ProductoTipo } from '../../../../models/productoTipo';
import { PedidoService } from '../../../../services/pedido.service';



@Component({
  selector: 'app-tipo-prducto',
  templateUrl: './tipoPedido.component.html',
})
export class TipoProductoComponent implements OnInit {
  public selectedValue: number;
  public tipoModelo: ProductoTipo;

 
  tipoAgregar: FormGroup;
  titulo = 'Agregar';
  public idTipoProducto: number = null;
  public chckActivado = false;
  public usuarioSession: any;
  public idRazonSocial: any;
  constructor(private formBuilder: FormBuilder,
              private pedidoService: PedidoService,
              public dialog: MatDialogRef<TipoProductoComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.tipoModelo = new ProductoTipo(0,"",0);
                this.dataService.guardado = false;
               }

  ngOnInit() {
      this.buildForm();
      this.dataService.mensajeAlert = '';
      this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
      this.idRazonSocial = this.usuarioSession.idRazonSocial;
  }

  


  cerrarModal() {
    this.dialog.close();
  }

  buildForm() {
    this.tipoAgregar = this.formBuilder.group({
      descripcion: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)])
    });
  }

  

  agregar() {
    const tipo = 'agregar';
  
    this.tipoModelo.idProductoTipo= null;
    this.tipoModelo.idRazonSocial = this.idRazonSocial
    this.pedidoService.agregarTipoProducto(this.tipoModelo).subscribe(
        (result) => {
          if (result !== 'null') {
            this.dataService.guardado = true;
            this.cerrarModal();
          } else {
            this.dataService.mensajeAlert = result;
          }
          
        },
        (error) => {
          console.log(error);
        }
    );
  }



  onSubmit() {
   
      this.agregar();
  
  }

 

}