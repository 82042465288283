export class Producto{
	constructor(
		public idProducto:number,
		public descripcion: string,
		public alto:number,
		public ancho:number,
		public profundidad:number,
		public peso:number,
		public SKU:string,
		public unidadMedida:string,
		public idUnidadMedida: number,
		public marca:string,
		public modelo:string,
		public codigoBarras:string,
		public idProductoTipo:number,
	    public precioUnitario: number,
        public idRazonSocial: number,
        public realizoAlta: number,
		public idExterno: string

	){}
}