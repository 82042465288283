import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../../utils/data.service';
import { Observable } from 'rxjs/Observable';
import { modalComponent } from '../../../../utils/modal/modal.component';
import { MatDialog } from '@angular/material';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material';
import { Gasto } from '../../../../models/programas/gasto';
import { GastoService } from '../../../../services/programa/gasto.service';



@Component({
  selector: 'app-gasto',
  templateUrl: './gasto.component.html',
    styleUrls: ['../../estilosModales.scss']
})
export class GastoComponent implements OnInit {
  public selectedValue: number;
  public gastoModelo: Gasto;

 
  tipoAgregar: FormGroup;
  titulo = 'Agregar';
  public idTipoProducto: number = null;
  public chckActivado = false;
  public idRazonSocial: any;
  public usuarioSession: any;
  public selectedTipoGasto: any;
    public idUsuario : any;
    public tipoGastos: any = [];
  constructor(private formBuilder: FormBuilder,
              private gastoService: GastoService,
              public dialog: MatDialogRef<GastoComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.gastoModelo = new Gasto(0,"",'',0,0,0,"");
                this.dataService.guardado = false;
               }

  ngOnInit() {
      this.buildForm();
      this.dataService.mensajeAlert = '';
          this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
         this.idUsuario = this.usuarioSession.idUsuario;
    this.idRazonSocial = this.usuarioSession.idRazonSocial;
    this.tipoGastos =[
      {id :  1,
      descripcion :  "Vale de Combustible",
       },
        {id :  2,
      descripcion :  "Gasto de Viaje",
       }
      ]
      
  }

  


  cerrarModal() {
    this.dataService.dialogRef.close();
  }

  buildForm() {
    this.tipoAgregar = this.formBuilder.group({
      descripcion: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
      costo: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
      tipoGasto: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
    });
  }

  

  agregar() {
    const tipo = 'agregar';
   this.gastoModelo.idRazonSocial = this.idRazonSocial
   this.gastoModelo.realizoAlta = this.idUsuario
    this.gastoModelo.idGasto= null;
    this.gastoModelo.idTipoGasto = this.selectedTipoGasto.id
    this.gastoModelo.tipoGasto = this.selectedTipoGasto.descripcion
    this.gastoService.agregarGasto(this.gastoModelo).subscribe(
        (result) => {
          if (result !== 'null') {
            this.dataService.guardado = true;
          } else {
            this.dataService.mensajeAlert = result;
          }
          this.cerrarModal();
        },
        (error) => {
          console.log(error);
        }
    );
  }



  onSubmit() {
   
      this.agregar();
  
  }

 

}