import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { utils } from '../utils/utils';
import * as $ from 'jquery';


@Injectable()
export class UnidadMedidaService {
    // public usuario:Array<any>;
   public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});
    //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});
   

    constructor(private _http: Http,  private _utils: utils) {
        this._utils = new utils();
    }
   
   
  consultarUnidadMedida(): Observable<any[]>{
   
      return this._http
        .post(`${this._utils.url}UnidadMedida/consultarUnidadMedida/`, {headers: this.headers}  )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }


  


 
   

}