import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers,ResponseContentType } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { utils } from '../utils/utils';
import * as $ from 'jquery';
import { Pais } from '../models/pais';

@Injectable()
export class ImportadorService {
    // public usuario:Array<any>;
   public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});
    //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});
   

    constructor(private _http: Http,  private _utils: utils) {
        this._utils = new utils();
    }


   getArchivoProductos(){
     let headers = new Headers({ 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

  return this._http.get(`${this._utils.url}Importador/getArchivoProductos/`,{responseType: ResponseContentType.Blob,headers:headers} )
      .map(response => {return response});
  
   }

   getArchivoSolicitudes(){
    let headers = new Headers({ 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

 return this._http.get(`${this._utils.url}Importador/getArchivoSolicitudes/`,{responseType: ResponseContentType.Blob,headers:headers} )
     .map(response => {return response});
 
  }

   getArchivoClientes(){
     let headers = new Headers({ 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

  return this._http.get(`${this._utils.url}Importador/getArchivoClientes/`,{responseType: ResponseContentType.Blob,headers:headers} )
      .map(response => {return response});
  
   }

   getArchivoDispositivos(){
     let headers = new Headers({ 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

  return this._http.get(`${this._utils.url}Importador/getArchivoDispositivos/`,{responseType: ResponseContentType.Blob,headers:headers} )
      .map(response => {return response});
  
   }

   getArchivoVehiculos(){
     let headers = new Headers({ 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

  return this._http.get(`${this._utils.url}Importador/getArchivoVehiculos/`,{responseType: ResponseContentType.Blob,headers:headers} )
      .map(response => {return response});
  
   }


   getArchivoOperadores(){
     let headers = new Headers({ 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

  return this._http.get(`${this._utils.url}Importador/getArchivoOperadores/`,{responseType: ResponseContentType.Blob,headers:headers} )
      .map(response => {return response});
  
   }

    
    cliente(file : File[],descripcion : any) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
       const formData = new FormData();
   formData.append('pedidos',file[0]);
   formData.append('clientes', file[1]);
   formData.append('dispositivos', file[2]);
   formData.append('txtDescripcion', descripcion);
        return this._http
          .post(`${this._utils.url}Importador/cargarClientes/`, formData )
          .map((response) =>{
               try {
            if (response.json() !== 'null') {
              return JSON.parse(response.json());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
        } 

         cargarOperadores(file : File,idRazonSocial : any) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
       const formData = new FormData();
   formData.append('operadores',file);
   
   formData.append('idRazonSocial', idRazonSocial);
        return this._http
          .post(`${this._utils.url}Importador/cargarOperadores/`, formData )
          .map((response) =>{
               try {
           if (response.text() !== 'null') {
              return response.text()
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
        } 

            cargarVehiculos(file : File,idRazonSocial : any) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
       const formData = new FormData();
   formData.append('vehiculos',file);
   
   formData.append('idRazonSocial', idRazonSocial);
        return this._http
          .post(`${this._utils.url}Importador/cargarVehiculos/`, formData )
          .map((response) =>{
               try {
            if (response.text() !== 'null') {
              return response.text()
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
        } 

          cargarProductos(file : File,idRazonSocial : any) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
       const formData = new FormData();
   formData.append('productos',file);
   
   formData.append('idRazonSocial', idRazonSocial);
        return this._http
          .post(`${this._utils.url}Importador/cargarProductos/`, formData )
          .map((response) =>{
               try {
          if (response.text() !== 'null') {
              return response.text()
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
        } 


        cargarSolicitudes(file : File,idRazonSocial : any) {
          // let params = new URLSearchParams();
          // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
         const formData = new FormData();
     formData.append('solicitudes',file);
     
     formData.append('idRazonSocial', idRazonSocial);
          return this._http
            .post(`${this._utils.url}Importador/cargarSolicitudes/`, formData )
            .map((response) =>{
                 try {
            if (response.text() !== 'null') {
                return response.text()
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
          } 

         cargarClientes(file : File,idRazonSocial : any) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
       const formData = new FormData();
   formData.append('clientes',file);
   
   formData.append('idRazonSocial', idRazonSocial);
        return this._http
          .post(`${this._utils.url}Importador/cargarCleintes/`, formData )
          .map((response) =>{
               try {
            if (response.text() !== 'null') {
              return response.text()
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
        } 
   

   
}
