import { Injectable} from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { utils } from '../utils/utils';

@Injectable()
export class AccesoService {
    public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});

    constructor(private _http: Http) {
        this._utils = new utils();
    }

    _utils: utils;

    consultarAccesos(): Observable<any[]> {

        return this._http.get(`${this._utils.url}acceso/consultarAccesos`, {headers: this.headers}).pipe(
        map((res: any) => {
            return res.json();
        }));
    }

    consultarUsuaraioAccesos(id): Observable<any[]> {

        return this._http.get(`${this._utils.url}acceso/consultarUsuarioAccesos?idUsuario=`+id, {headers: this.headers}).pipe(
        map((res: any) => {
            return res.json();
        }));
    }

    agregarUsuarioAccesos(params: any): Observable<any[]> {
        let json = JSON.stringify(params);

        return this._http.post(`${this._utils.url}acceso/agregarUsuarioAccesos`, json, {headers: this.headers}).pipe(
        map((res: any) => {
            return res.json();
        }));
    }
}