export class Gasto{
	constructor(
		public idGasto:number,
		public descripcion:string,
		public costo: string,
		public idRazonSocial: number,
		public realizoAlta: number,
		public idTipoGasto: number,
		public tipoGasto : string
	){}
}