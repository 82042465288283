import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../../utils/data.service';
import { Observable } from 'rxjs/Observable';
import { modalComponent } from '../../../../utils/modal/modal.component';
import { MatDialog } from '@angular/material';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material';
import { Producto } from '../../../../models/producto';
import { ProductoService } from '../../../../services/producto.service';
import { UnidadMedidaService } from '../../../../services/unidadMedida.service';
import { TipoProductoComponent } from 'src/app/pages/views/programas/tipoPedido/tipoProducto.component';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['../../estilosModales.scss']
})
export class ProductoComponent implements OnInit {
  public usuarioSession: any;
  public idRazonSocial: any;
  public idUsuario: any;
  public productoModelo: Producto;
  public idProducto : any;
  listaTipoProductos : any;
  listaUnidadMedida : any;
  productoAgregar: FormGroup;
  public datosProducto:Array<any>;
  selectedTipo : any;
  selectUnidad: any;
  dataSource: any; 
  isZAM: number;
  isZAMVal: boolean;
  
  constructor(private formBuilder: FormBuilder, private productoService: ProductoService, private unidadMedidaService : UnidadMedidaService,
    public dialog: MatDialogRef<ProductoComponent>, public dialogRef: MatDialog, public dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.productoModelo = new Producto(0,'',0,0,0,0,'','',0,'','','',0,0,0,0,'')
      this.dataService.guardado = false;
  }
   
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
    this.idUsuario = this.usuarioSession.idUsuario;
    this.idRazonSocial = this.usuarioSession.idRazonSocial;
    this.isZAM = this.usuarioSession.activoZAM;

    if(this.isZAM != 0){
      this.isZAMVal = true;
    }else{
      this.isZAMVal = false;
    }

    this.llenarDDL();
    this.buildForm();
    this.dataService.mensajeAlert = '';

    if(this.idProducto != 0 && this.idProducto != null){
      this.consultarProducto();
    }
           
  }

  cerrarModal() {
    this.dataService.dialogRef.close();
  }

  buildForm() {
    this.productoAgregar = this.formBuilder.group({
      descripcion: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
      alto: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      ancho: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      profundidad: this.formBuilder.control(null, [Validators.maxLength(50)]),
      peso: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      SKU: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
      unidadMedida: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
      marca: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      modelo: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      codigoBarra: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      precioUnitario: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      tipoProducto  : this.formBuilder.control(null,[Validators.required, Validators.maxLength(50)]),
      idExterno: this.formBuilder.control(null, []),
    });
  }


  llenarDDL(){
    this.productoService.consultarTipoProductos(this.idRazonSocial).subscribe(
      (result) => {
        if(result !=null){
          this.listaTipoProductos = result;
          this.consultarUnidadMedida()
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  consultarUnidadMedida(){
    this.unidadMedidaService.consultarUnidadMedida().subscribe(
      (result) => {
        if(result !=null){
          this.listaUnidadMedida = result;  
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  consultarProducto(){
    this.productoService.consultarProducto(this.idProducto).subscribe(
      (result) => {
        if(result !=null){
          this.productoModelo = result[0];
          this.bindForm();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  accion(){
    this.bindModel();
    if(this.idProducto != 0 && this.idProducto != null){
      this.editar()
    }else{
      this.agregar()
    }
  }
   
   agregar() {
    this.productoModelo.idProducto = null;

    this.productoService.agregarProducto(this.productoModelo).subscribe(
      (result) => {
        if (result !== 'null') {
          this.dataService.guardado = true;
        } else {
          this.dataService.guardado = false;
        }
        this.cerrarModal();
      },
      (error) => {
        console.log(error);
      }
    );
  }


  editar() {
    this.productoModelo.idProducto = this.idProducto;

    this.productoService.actualizarProducto(this.productoModelo).subscribe(
        (result) => {
          if (result !== 'null') {
             this.dataService.guardado = true;
          } else {
            this.dataService.guardado = false;
          }
          this.cerrarModal();
        },
        (error) => {
          console.log(error);
        }
    );
  }

  agregarProductoTipo() {
    const dialogRef = this.dialogRef.open(TipoProductoComponent, 
      { data: { },
        disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      result => {
        this.llenarDDL();
      }
    );
  }

  bindModel() {
    this.productoModelo.idRazonSocial = this.idRazonSocial;
    this.productoModelo.realizoAlta = this.idUsuario;
    this.productoModelo.descripcion = this.productoAgregar.get('descripcion').value;
    this.productoModelo.ancho = this.productoAgregar.get('ancho').value;
    this.productoModelo.alto = this.productoAgregar.get('alto').value;
    this.productoModelo.idProductoTipo = this.productoAgregar.get('tipoProducto').value;
    this.productoModelo.idExterno = this.productoAgregar.get('idExterno').value;
    this.productoModelo.SKU = this.productoAgregar.get('SKU').value;
    this.productoModelo.profundidad = this.productoAgregar.get('profundidad').value;
    this.productoModelo.unidadMedida = this.productoAgregar.get('unidadMedida').value;
    this.productoModelo.idUnidadMedida = this.productoAgregar.get('unidadMedida').value;
    this.productoModelo.peso = this.productoAgregar.get('peso').value;
    this.productoModelo.marca = this.productoAgregar.get('marca').value;
    this.productoModelo.modelo = this.productoAgregar.get('modelo').value;
    this.productoModelo.codigoBarras = this.productoAgregar.get('codigoBarra').value;
    this.productoModelo.precioUnitario = this.productoAgregar.get('precioUnitario').value;
  }

  bindForm() {
    this.productoAgregar.get('descripcion').setValue(this.productoModelo.descripcion);
    this.productoAgregar.get('ancho').setValue(this.productoModelo.ancho);
    this.productoAgregar.get('alto').setValue(this.productoModelo.alto);
    this.productoAgregar.get('tipoProducto').setValue(this.productoModelo.idProductoTipo);
    this.productoAgregar.get('idExterno').setValue(this.productoModelo.idExterno);
    this.productoAgregar.get('SKU').setValue(this.productoModelo.SKU);
    this.productoAgregar.get('profundidad').setValue(this.productoModelo.profundidad);
    this.productoAgregar.get('unidadMedida').setValue(this.productoModelo.idUnidadMedida);
    this.productoAgregar.get('peso').setValue(this.productoModelo.peso);
    this.productoAgregar.get('marca').setValue(this.productoModelo.marca);
    this.productoAgregar.get('modelo').setValue(this.productoModelo.modelo);
    this.productoAgregar.get('codigoBarra').setValue(this.productoModelo.codigoBarras);
    this.productoAgregar.get('precioUnitario').setValue(this.productoModelo.precioUnitario);
  }

}
