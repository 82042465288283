import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { utils } from '../../utils/utils';
import * as $ from 'jquery';
import { Gasto } from '../../models/programas/gasto';

@Injectable()
export class GastoService {
    // public usuario:Array<any>;
   public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});
    //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});
   

    constructor(private _http: Http,  private _utils: utils) {
        this._utils = new utils();
    }
     
   
   
  consultarGastos(idRazonSocial): Observable<Gasto[]>{
   
    let param = {
    idRazonSocial: idRazonSocial
  }
  const json = JSON.stringify(param);
   
  
      return this._http
        .post(`${this._utils.url}Gasto/consultarGastos/`,json, {headers: this.headers}  )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }

  

  agregarGasto(gasto: Gasto){

  gasto.realizoAlta = 1;
  const json = JSON.stringify(gasto);
   return this._http
          .post(`${this._utils.url}Gasto/guardarGasto/`, json, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return response.statusText;
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
  }

   guardarGastoPrograma(gasto,idRuta,vale){
  var params = {
    gasto : gasto,
    vale : vale
  }
  const json = JSON.stringify(params);
   return this._http
          .post(`${this._utils.url}Gasto/guardarGastoPrograma/`, json, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return response.statusText;
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
  }

     gastosPrograma(idRuta){
  let gasto={
    idRuta: idRuta
  }
  const json = JSON.stringify(gasto);
   return this._http
          .post(`${this._utils.url}Gasto/gastosPrograma/`, json, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                 return JSON.parse(response.text());
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
  }

  valesPrograma(idRuta){
    let gasto={
      idRuta: idRuta
    }
    const json = JSON.stringify(gasto);
     return this._http
            .post(`${this._utils.url}Gasto/valesPrograma/`, json, {headers: this.headers} )
            .map((response) => {
              try {
                if(response.statusText !== 'null' && response.statusText == 'OK'){
                  console.log(response.text());
                   return JSON.parse(response.text());
                }else{
                  return null;
                }
              } catch (error) {
                console.log(error);
                return null; 
              } 
            });
    }



 
   

}